import React from "react"
import { graphql } from "gatsby"
import GridList from '../../components/GridList'; 

import Layout from "../../components/layout"

class Products extends React.Component {
  state={ windowWidth: 0 }
  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth })
  }
  render () {
    const { data } = this.props;
    const { meta, title } = data.markdownRemark.frontmatter; 
    const { edges } = data.allMarkdownRemark;
    return (
      <Layout meta={meta}> 
        <header data-aos='fade-in' className='page-title'>
          <h1>
            { title }
            <span style={{width: 100, height: 6, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
          </h1>
        </header>
        <div style={{margin: '75px 0'}}>
          <GridList productsData={edges} windowWidth={this.state.windowWidth} />
        </div>
      </Layout>     
    ) 
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: {title: {eq: "Produkter"}}) {
    	frontmatter{
        title
        meta {
          title
          description
          keywords
          robots
        }
      }
      html
    }
    allMarkdownRemark (
      filter: {
        frontmatter: {path: {regex: "//produkter//"}}
      }
      sort: {
        fields: frontmatter___title, order: ASC
      }
    ) {
    	edges {
        node {
          id
          frontmatter {
            path
            image
            title
            cols
          }
        }
      }
  	}
  }
`

export default Products
